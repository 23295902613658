import { ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import hljs from 'highlight.js';
import xml from 'highlight.js/lib/languages/xml';

@Component({
	selector: 'app-xml-view',
	templateUrl: './xml-view.component.html',
	styleUrls: ['./xml-view.component.scss']
})
export class XmlViewComponent implements OnInit,OnChanges {
	@Input() srcUrl: string;

	xmlContent: string = '';
	highlightedXml: any = undefined;

	constructor(private readonly http: HttpClient,private chengeDetectorRef: ChangeDetectorRef) {
		hljs.registerLanguage('xml', xml);
	}

	ngOnInit(): void {
		this.loadAndHighlight(this.srcUrl);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['srcUrl']) {
			this.loadAndHighlight(changes['srcUrl'].currentValue);
			this.chengeDetectorRef.detectChanges();
		}
	}

	loadAndHighlight(url: string) {
		this.http.get(url, { responseType: 'blob' }).subscribe({
			next: (fileBlob) => {
				fileBlob.text().then((content) => {
					this.xmlContent = content;
					this.highlightedXml = hljs.highlight(this.xmlContent, { language: 'xml' }).value;
					this.chengeDetectorRef.detectChanges();
				});
			},
			error: (err) => {
				console.error(err);
				// TODO: Error handling here
			}
		});
	}
}
